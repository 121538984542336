/*
 * @Description: 混入方法
 * @Author: ChenXueLin
 * @Date: 2021-12-22 09:29:23
 * @LastEditTime: 2022-05-09 13:40:48
 * @LastEditors: ChenXueLin
 */
import { addServiceRecord } from "@/api";
import { printError } from "@/utils/util";
import eventBus from "@/bus";
export default {
  data() {
    return {
      /****添加场景*******/
      selectSceneDialog: false,
      addContactVisible: false, //添加联系人弹框
      tableData: [], //任务项表数据
      deleteData: [], //要删除的任务项
      equipList: [], //从服务详情页面跳转带过来的设备id
      queryId: "", //带过来的corpId
      queryTableData: [], //通过equipId查询出来的数据
      selectAddressDialog: false
    };
  },
  props: {},
  components: {},
  computed: {},

  created() {},
  methods: {
    //服务详情添加成功后跳转逻辑
    toPage() {
      this.closeTag(this.$route);
      if (this.visitedViews.length) {
        const latestView = this.visitedViews.slice(-1)[0];
        this.$router.push(latestView);
      } else {
        this.routerPush({
          name: "workOrderManage/allWorkOrder",
          params: {
            refresh: false
          }
        });
      }
    },
    //添加任务项
    addScene() {
      if (this.corpInfo.corpId) {
        this.selectSceneDialog = true;
      } else {
        this.$message.warning("请先选择客户名称");
        return;
      }
    },
    //选择场景数据
    handleSelectionChange(val) {
      this.deleteData = val;
    },
    //添加的场景列表
    getData(data) {
      data.map((item, index) => {
        item.problemType = ""; //问题类型
        item.quesName = ""; //问题名称
        item.newSceneName = ""; //新场景名称
        item.newSceneType = ""; //新场景类型
        item.requestDate = ""; //开通时间/报停时间
        item.stopReason = index > 0 ? "同上" : ""; //报停原因
        item.needToRemove = index > 0 ? 2 : 0; //是否拆机
        //判断数组中是否已有选中的数据
        if (
          this.tableData.some(
            v =>
              v.thirdClassId == item.thirdClassId &&
              v.sceneName != "未知场景" &&
              v.sceneId == item.sceneId &&
              v.terminalId == item.terminalId &&
              v.equipCode == item.equipCode
          )
        ) {
          return;
        } else {
          let idx = this.tableData.lastIndexOf(
            each => each.terminalId == item.terminalId
          );
          if (idx > -1) {
            //如果存在相同组合的情况,
            this.tableData.splice(idx, 0, item);
          } else {
            this.tableData.push(item);
          }
        }
      });
      for (let i = 0; i < this.tableData.length; i++) {
        if (i == 0) {
          this.tableData[i].show = true;
          this.tableData[i].show2 = true;
        } else {
          this.tableData[i].show =
            this.tableData[i].sceneId == this.tableData[i - 1].sceneId
              ? false
              : true;
          this.tableData[i].show2 =
            this.tableData[i].terminalId == this.tableData[i - 1].terminalId
              ? false
              : true;
        }
      }
      this.tableData.forEach(item => {
        if (item.sceneName == "未知场景") {
          item.show = true;
          item.show2 = true;
        }
      });
      this.selectSceneDialog = false;
      this.sortData();
    },
    //点击确定添加场景类型
    confirmSelect(selectData) {
      if (selectData.length) {
        selectData.map(item => {
          item.newSceneName = "";
          item.newSceneType = "";
          item.dockIds = [];
          item.dockName = "";
        });
        this.tableData = _.cloneDeep(selectData).concat(
          _.cloneDeep(this.tableData)
        );
        var obj = {};
        this.tableData = this.tableData.reduce((item, next) => {
          obj[next.sceneId]
            ? ""
            : (obj[next.sceneId] = true && item.push(next));
          return item;
        }, []);
      }
    },
    //数组排序
    sortData() {
      let result = [];
      let terminalIds = Array.from(
        new Set(
          this.tableData.map(item => {
            return item.terminalId;
          })
        )
      );
      terminalIds.map(item => {
        var arr = this.tableData.filter(v => item == v.terminalId);
        arr.map(each => {
          result.push(each);
        });
      });
    },
    //删除任务项
    handleDelete() {
      if (!this.deleteData.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      let tableData = _.cloneDeep(this.tableData);
      let deleteData = _.cloneDeep(this.deleteData);
      this.tableData = tableData.filter(v =>
        deleteData.every(n => {
          return !(
            n.sceneId == v.sceneId &&
            n.terminalId == v.terminalId &&
            n.equipId == v.equipId
          );
        })
      );
      this.getData([]);
    },
    //关闭弹框
    handleClose(name) {
      this[name] = false;
    },
    //添加服务详情
    async addServiceRecordReq(
      expectSolveTimeTimestamp = "",
      serverDetails,
      taskId
    ) {
      try {
        if (this.setLoading) {
          this.loading = true;
        } else {
          this.$emit("setLoading", true);
        }
        let res = await addServiceRecord({
          taskId,
          customerId: this.corpInfo.corpId,
          customerName: this.corpInfo.corpName,
          expectSolveTimeTimestamp,
          //期望解决时间
          ...this.serviceTypeForm, //服务一级类型,2级类型
          phoneCallName: this.corpInfo.peopleName, //来电人名称
          phoneTypeCode: this.callType, //来去电类型码值
          phoneNumber: this.customerNumber, //来电人号码
          record: this.mainUniqueId, //通话ID
          serverDetails, //服务内容
          serverResultCode: this.serverResultCode, //服务结果
          serverTimeTimestamp: new Date().getTime()
        });
        if (res.code === "OK") {
          this.$message.success("创建成功");
          eventBus.$emit("UPDATE_VISITE_RESULT");
          this.tableData = [];
          if (this.iscloseWindowHandle) {
            this.iscloseWindowHandle();
          } else {
            this.$emit("iscloseWindowHandle");
          }
          this.toPage();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.$emit("setLoading", true);
      }
    },
    // 关闭来去电弹屏
    iscloseWindowHandle() {
      if (!this.needClose) {
        return;
      }
      setTimeout(() => {
        this.closeWindow();
      }, 1000);
    },
    async closeWindow() {
      if (!this.needClose) {
        return;
      }
      window.close();
      var userAgent = navigator.userAgent;
      if (
        userAgent.indexOf("Firefox") != -1 ||
        userAgent.indexOf("Chrome") != -1
      ) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    }
  }
};
