<!--
 * @Description: 设备维修
 * @Author: ChenXueLin
 * @Date: 2021-10-12 13:39:18
 * @LastEditTime: 2022-07-04 10:52:45
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <!-- 任务项内容 -->
    <div class="edit-content" style="margin-bottom: 20px">
      <div class="associated-info">
        <div class="right-button">
          <el-button type="primary" @click="addScene">
            添加任务项
          </el-button>
          <el-button type="primary" @click="handleDelete">
            删除任务项
          </el-button>
          <el-button type="primary" @click="handleWrite">
            批量填写
          </el-button>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sceneName"
          label="场景名称"
          width="120"
          align="center"
          fixed
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show">{{ row.sceneName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="sceneTypeName"
          label="场景类型"
          width="120"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show">{{ row.sceneTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="vehicleColorName"
          label="车牌颜色"
          width="110"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show">{{ row.vehicleColorName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="secondClassName"
          label="安装组合"
          width="140"
          align="center"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="row.show2">{{ row.secondClassName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="thirdClassName"
          label="已安装商品"
          width="120"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="firstClassName"
          label="商品分类"
          width="120"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="equipCode"
          label="设备编号"
          width="120"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="fixStartDateStr"
          label="保修期始"
          width="110"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="fixEndDateStr"
          label="保修期末"
          width="110"
          align="center"
          header-align="center"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="problemType"
          label="问题类型"
          width="200"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.show">
              <el-button
                type="text"
                @click="slectQues(scope.row, scope.$index)"
              >
                {{
                  scope.row.problemType ? scope.row.problemType : "选择问题类型"
                }}
              </el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="problemType"
          label="问题描述"
          width="200"
          align="center"
          header-align="center"
        >
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.show"
              v-model="scope.row.quesName"
              @input="handleInput(scope)"
              maxlength="50"
            ></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 批量填写弹框 -->
    <el-dialog
      v-dialogDrag
      title="批量填写"
      :visible.sync="writeDescVisible"
      width="500px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      :element-loading-background="loadingBackground"
      custom-class="write-dialog"
    >
      <el-form
        ref="writeDescForm"
        :model="writeDescForm"
        :rules="writeDescFormRules"
        :inline="true"
      >
        <el-form-item label="问题描述" prop="desc">
          <el-input
            v-model.trim="writeDescForm.desc"
            placeholder="问题描述"
            maxlength="50"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmWrite">确定</el-button>
      </div>
    </el-dialog>
    <!-- 转服务单指派弹框 -->
    <el-dialog
      v-dialogDrag
      title="指派部门/指派人"
      :visible.sync="dispatchVisible"
      width="500px"
      :before-close="handleDispatch"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="write-dialog"
    >
      <el-form ref="dispatchForm" :model="dispatchForm" :inline="true">
        <el-form-item
          label="指派类型"
          prop="solueIsDept"
          :rules="{
            required: true,
            message: '请选择指派类型',
            trigger: 'blur'
          }"
        >
          <el-radio-group v-model="dispatchForm.solueIsDept">
            <el-radio :label="1">部门</el-radio>
            <el-radio :label="0">员工</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="指派部门"
          prop="departmentId"
          v-if="dispatchForm.solueIsDept == 1"
          :rules="{
            required: true,
            message: '请选择指派部门',
            trigger: 'blur'
          }"
        >
          <e6-select
            v-model="dispatchForm.departmentId"
            :dataList="treeData"
            :filterable="true"
            :slotTemplate="'tree'"
            :multiple="false"
            :issingleleaf="false"
            ref="t_select"
          ></e6-select>
        </el-form-item>
        <el-form-item
          label="指派处理人"
          prop="solveRpid"
          v-if="dispatchForm.solueIsDept == 0"
          :rules="{
            required: true,
            message: '请选择指派处理人',
            trigger: 'blur'
          }"
        >
          <all-user-search
            v-model="dispatchForm.solveRpid"
            clear
            placeholder="指派处理人"
            title="指派处理人"
            :propsConfig="{
              id: 'employeeId',
              label: 'userName'
            }"
          >
          </all-user-search>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleDispatch">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </div>
    </el-dialog>
    <!-- 问题类型弹框 -->
    <el-dialog
      v-dialogDrag
      title="问题类型"
      :visible.sync="quesTypeVisible"
      width="600px"
      :before-close="closeQuesDialog"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="write-dialog"
    >
      <el-form
        ref="questionForm"
        :model="questionForm"
        :rules="questionFormRules"
        :inline="true"
      >
        <el-form-item label="一级问题类型" prop="firstQuesType">
          <e6-vr-select
            :data="firstQuesList"
            v-model="questionForm.firstQuesType"
            placeholder="一级问题类型"
            title="一级问题类型"
            clearable
            :props="{
              id: 'id',
              label: 'errorReason'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="二级问题类型" prop="secondQuesType">
          <e6-vr-select
            :data="secondQuesList"
            v-model="questionForm.secondQuesType"
            placeholder="二级问题类型"
            title="二级问题类型"
            :props="{
              id: 'id',
              label: 'errorReason'
            }"
            clearable
          ></e6-vr-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeQuesDialog">取消</el-button>
        <el-button type="primary" @click="confirmSelect">确定</el-button>
      </div>
    </el-dialog>
    <!-- 选择场景 -->
    <select-scene
      :selectSceneDialog="selectSceneDialog"
      @handleClose="handleClose"
      @getData="getData"
      :corpId="corpInfo.corpId"
    ></select-scene>
  </div>
</template>

<script>
import allUserSearch from "@/components/allUserSearch";
import {
  getFrameworkTree,
  getQuestion,
  createRepairTask,
  addRepairService,
  checkCreateTask
} from "@/api";

import base from "@/mixins/base";
import { printError } from "@/utils/util";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import minxins from "../minxins";
export default {
  name: "addRepairOrder",
  components: { SelectScene, allUserSearch },
  data() {
    return {
      /*****问题类型******/
      dialogLoading: false,
      firstQuesList: [], //一级问题下拉框
      secondQuesList: [], //二级问题下拉框
      quesTypeVisible: false,
      questionForm: {
        firstClassId: "", //设备分类
        firstQuesType: "", //一级问题
        secondQuesType: "" //二级问题
      },
      questionFormRules: {
        firstQuesType: [
          {
            required: true,
            message: "请选择一级问题类型",
            trigger: ["blur", "change"]
          }
        ],
        secondQuesType: [
          {
            required: true,
            message: "请选择二级问题类型",
            trigger: ["blur", "change"]
          }
        ]
      },
      currentIndex: "", //点击的当前行
      //批量填写弹框
      selectCloumn: [], //要填写的数据
      writeDescVisible: false,
      writeDescForm: {
        desc: ""
      },
      writeDescFormRules: {
        desc: [
          {
            required: true,
            message: "请输入问题描述",
            trigger: ["blur", "change"]
          }
        ]
      },
      /***转服务单弹框****/
      treeData: [],
      dispatchVisible: false, //指派类型弹框
      dispatchForm: {
        solveRpid: "", //指派处理人
        solueIsDept: 1, //指派类型1部门0员工
        departmentId: [] //指派部门
      }
    };
  },
  props: [
    "corpInfo",
    "serviceTypeForm",
    "callType",
    "customerNumber",
    "mainUniqueId",
    "serverResultCode",
    "serverDetailsForm",
    "writeForm"
  ],
  mixins: [base, minxins],
  created() {
    this.initData();
  },
  watch: {
    // 监听一级分类选择更改
    "questionForm.firstQuesType": {
      immediate: true,
      handler(val, oldVal) {
        if (val && val != oldVal) {
          this.questionForm.secondQuesType = "";
          this.queryQuestion(val);
        } else {
          this.secondQuesList = [];
          this.questionForm.secondQuesType = "";
        }
      }
    },
    // 监听客户名称更改
    "corpInfo.corpId": {
      immediate: true,
      handler(val, oldVal) {
        if (val !== "" && val != oldVal) {
          this.tableData = [];
        }
      }
    }
  },
  computed: {},
  methods: {
    //初始化数据
    async initData() {
      let promiseList = [
        getFrameworkTree() //获取部门结构
      ];
      let [frameworkRes] = await Promise.all(promiseList);
      this.handleTreeData(frameworkRes.data.children);
    },
    //处理树形结构
    handleTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        data[i]["label"] = data[i].name;
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    },
    /********问题类型选择***********/
    slectQues(row, index) {
      this.questionForm.firstClassId = row.firstClassId;
      this.currentIndex = index;
      this.quesTypeVisible = true;
      let filterArr = this.tableData.filter(item => {
        return item.sceneId == row.sceneId;
      });
      this.firstClassId = filterArr.map(item => {
        return item.firstClassId;
      }); //商品分类id
      this.queryQuestion(0);
    },
    //获取一二级问题类型
    async queryQuestion(pid) {
      try {
        let res = await getQuestion({
          firstClassIdList: this.firstClassId,
          parentId: pid
        });
        if (pid == 0) {
          this.firstQuesList = this.getFreezeResponse(res, {
            path: "data"
          });
        } else {
          this.secondQuesList = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      }
    },
    //确定选中问题类型
    confirmSelect() {
      this.$refs.questionForm.validate(valid => {
        if (valid) {
          //  给table赋值
          this.tableData[
            this.currentIndex
          ].firstQuesType = this.questionForm.firstQuesType;
          this.tableData[
            this.currentIndex
          ].secondQuesType = this.questionForm.secondQuesType;
          this.tableData[
            this.currentIndex
          ].firstQuesTypeName = this.firstQuesList.filter(
            item => item.id == this.questionForm.firstQuesType
          )[0].errorReason;
          this.tableData[
            this.currentIndex
          ].secondQuesTypeName = this.secondQuesList.filter(
            item => item.id == this.questionForm.secondQuesType
          )[0].errorReason;
          this.tableData[this.currentIndex].problemType =
            this.tableData[this.currentIndex].firstQuesTypeName +
            "-" +
            this.tableData[this.currentIndex].secondQuesTypeName;
          this.closeQuesDialog();
        }
      });
    },
    //关闭问题类型选择框
    closeQuesDialog() {
      this.questionForm = {
        firstClassId: "", //设备分类
        firstQuesType: "", //一级问题
        secondQuesType: "" //二级问题
      };
      this.$refs.questionForm.resetFields();
      this.quesTypeVisible = false;
    },
    /********问题描述填写*************/
    handleInput(scope) {
      this.clickInputValue = scope.row.quesName;
      this.clickInputIndex = scope.$index;
    },
    //点击批量填写
    handleWrite() {
      if (this.clickInputValue) {
        this.tableData.map((item, index) => {
          console.log(this.clickInputIndex);
          if (index >= this.clickInputIndex) {
            item.quesName = this.clickInputValue;
          }
        });
      }
    },
    //确认填写
    confirmWrite() {
      this.selectCloumn.map(item => {
        item.quesName = this.writeDescForm.desc;
      });
      this.closeDialog();
    },
    //关闭批量填写
    closeDialog() {
      this.writeDescForm = {
        desc: ""
      };
      this.selectCloumn = [];
      this.$refs.multipleTable.clearSelection();
      this.$refs.writeDescForm.resetFields();
      this.writeDescVisible = false;
    },
    //点击转单
    toTaskOrder(type) {
      //转服务单，转任务单
      if (!this.tableData.length) {
        this.$message.warning("请至少选择一个任务项");
        return;
      }
      //校验问题类型和问题描述是否全写
      let ruleArr = this.tableData.filter(item => item.show);
      if (ruleArr.some(each => !each.firstQuesType)) {
        this.$message.warning("请检查问题类型是否全部选择");
        return;
      }
      if (ruleArr.some(each => !each.quesName)) {
        this.$message.warning("请检查问题描述是否全部填写");
        return;
      }
      if (type == 1) {
        //转维修服务单
        this.dispatchVisible = true;
      }
      if (type == 2) {
        //转维修任务单
        this.checkCreateTaskReq();
      }
    },
    //关闭指派弹框
    handleDispatch() {
      this.dispatchForm = {
        solveRpid: "", //指派处理人
        solueIsDept: 1, //指派类型1部门0员工
        departmentId: [] //指派部门
      };
      this.dispatchVisible = false;
    },
    confirmAdd() {
      this.$refs.dispatchForm.validate(valid => {
        if (valid) {
          this.addRepairServiceReq();
        }
      });
    },
    //创建维修服务单请求
    async addRepairServiceReq() {
      try {
        this.dialogLoading = true;
        let res = await addRepairService({
          ...this.writeForm,
          corpName: this.corpInfo.corpName,
          corpId: this.corpInfo.corpId,
          solveRpid:
            this.dispatchForm.solueIsDept == 1
              ? this.dispatchForm.departmentId.join("")
              : this.dispatchForm.solveRpid,
          items: this.tableData
        });
        if (res.code == "OK") {
          this.handleDispatch();
          //调用添加服务记录
          this.addServiceRecordReq(
            this.writeForm.expecttime,
            this.serverDetailsForm.serverDetails,
            res.data.id
          );
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //创建维修任务单请求
    async createRepairTaskReq() {
      try {
        this.$emit("setLoading", true);
        let res = await createRepairTask({
          ...this.writeForm,
          corpId: this.corpInfo.corpId,
          corpName: this.corpInfo.corpName,
          taskItemReqVOList: this.tableData
        });
        if (res.code == "OK") {
          // this.$message.success("创建成功");
          this.addServiceRecordReq(
            this.writeForm.expecttime,
            this.serverDetailsForm.serverDetails,
            res.data.id
          );
          this.$emit("iscloseWindowHandle");
        }
      } catch (error) {
        printError(error);
      } finally {
        this.$emit("setLoading", false);
      }
    },
    //校验工作中的任务项
    async checkCreateTaskReq() {
      try {
        this.$emit("setLoading", true);
        let sceneName = this.tableData.map(item => {
          return item.sceneName;
        });
        let res = await checkCreateTask({
          corpId: this.corpInfo.corpId,
          sceneName,
          workSecondClass: "10",
          terminalIdList: this.tableData.map(item => {
            return item.terminalId;
          })
        });
        this.unSceneName = res.data.sceneName;
        let arrJoin = _.cloneDeep(this.unSceneName).join(",");
        let filterSceneName = [
          ...new Set(
            _.cloneDeep(this.tableData).map(item => {
              return item.sceneName;
            })
          )
        ];
        if (this.unSceneName.length) {
          if (this.unSceneName.length < filterSceneName.length) {
            this.$emit("setLoading", false);
            let str =
              "场景" + arrJoin + "正在作业中，是否确定过滤掉【作业中】的场景？";
            this.$confirm(str, "确认提交", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                this.createRepairTaskReq();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消"
                });
              });
          }
          if (this.unSceneName.length == filterSceneName.length) {
            this.$emit("setLoading", false);
            this.$message.warning(`${arrJoin}正在作业中，没有可创建的任务项`);
            return;
          }
        } else {
          this.createRepairTaskReq();
        }
      } catch (error) {
        printError(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  padding-bottom: 0px;
  .e6-vr-select {
    width: 100%;
  }
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
.table-input {
  width: 300px;
}
/deep/.write-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 260px;
    }
  }
}
.isFinish {
  padding: 20px;
  box-sizing: border-box;
}
.write-box {
  /deep/.el-form {
    .e6-vr-select {
      width: 260px !important;
    }
    .el-form-item {
      .el-input {
        width: 260px;
      }
      .el-textarea {
        width: 400px;
      }
    }
  }
}
</style>
